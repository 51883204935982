<template>
    <div class="left-filters-area">
        <div class="upload-profile-container">
            <button-prime class="p-0 transparent edit-btn">
                <i class="pi pi-pencil"></i>
            </button-prime>
            <label for="user-profile">
                <div 
                class="upload-profile-box" 
                :style="{ backgroundImage: customer.image_preview ? `url(${customer.image_preview})` : '' }"
            >
                <h4 v-if="!customer.image_preview">Upload profile</h4>
                <input type="file" id="user-profile" @change="handleFileUpload" />
            </div>
            </label>
        </div>

        <div class="user-profile-infomation-area">
            <div class="user-pro-info-list">
                <label>Name</label>
                <inputText-prime type="text" placeholder="Enter Name" v-model="customer.full_name"/>
            </div>

            <div class="user-pro-info-list">
                <label>Email</label>
                <inputText-prime type="email" placeholder="Enter Email" v-model="customer.email" />
            </div>

            <div class="user-pro-info-list">
                <label>Phone</label>
                <vue-tel-input 
                mode="international"
                validCharactersOnly
                v-model="customer.phone"
                default-country="ae"
                :inputOptions="{placeholder : '+971555555555'}"
                :dropdown-options="{onlyCountries: ['ae'], showFlags: true, disabled: true, showDialCodeInList: true, }"
                @on-input="onInput"
                ></vue-tel-input>
            </div>
            
            <div class="user-pro-info-list">
                <label>Nationality</label>
                <dropdown-prime filter v-model="customer.nationality" :options="nationalities" optionLabel="name" placeholder="Select Country" class="w-full" />
            </div>

            <button-prime label="Save" @click="save_profile" :loading="loading" class="profile-info-btn" />
        </div>
        <toast-prime />
    </div>
</template>

<script>
import 'swiper/css/scrollbar';
import axios from 'axios'

export default {
    data() {
        return {
            loading : false,
            is_phone_number_valid : false,
            selectedStatus: 'all',
            quotationSelectedStatus: 'active',
            nationalities : [],
            customer : {
                full_name : null,
                email : null,
                phone : localStorage.getItem("mebrokers.customer.phone.number"),
                nationality : null,
                file: null,
                file_name: null,
                file_size: null, 
                image_preview : null
            } 
        }
    },
    components: {

    },
    mounted() {
        this.get_user_details();
        this.get_all_data();
    },
    methods: {
        save_profile() {
            if (this.customer.full_name == '' || this.customer.full_name == null) {
                this.$toast.add({ severity: 'error', detail: 'Full name is required!', life: 3000 });
                return false;
            }
            if (!/\s/.test(this.customer.full_name)) { 
                this.$toast.add({ severity: 'error', detail: 'Full name must include a space (e.g., first and last name).', life: 3000 });
                return false;
            }
            if (this.customer.email == '' || this.customer.email == null) {
                this.$toast.add({ severity: 'error', detail: 'Email is required!', life: 3000 });
                return false;
            }
            if (!this.validate_email(this.customer.email)) {
                this.$toast.add({ severity: 'error', detail: 'Incorrect email!', life: 3000 });
                return false;
            }
            if (this.customer.phone == null || this.customer.phone == '') {
                this.$toast.add({ severity: 'error', detail: 'Phone number is required!', life: 3000 });
                return false;
            }
            if (!this.is_phone_number_valid) {
                this.$toast.add({ severity: 'error', detail: 'Invalid Phone number!', life: 3000 });
                return false;
            }
            if (this.customer.nationality == null) {
                this.$toast.add({ severity: 'error', detail: 'Nationality is required!', life: 3000 });
                return false;
            }

            this.loading = true;
            this.customer.phone = this.customer.phone.replace(/\s+/g, '');

            axios.post(this.api_url + 'customer/save-user-details', 
                this.customer, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('mecompare_auth_token'),
                    },
                }
            ).then(response => {
                this.loading = false;
                this.$toast.add({ severity: 'success', detail: response.data.data.message, life: 3000 });
            }).catch(error => {
                this.loading = false;
                if (error.response && error.response.status === 422) {
                    // Handle validation errors
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            this.$toast.add({ severity: 'error', detail: errors[key][0], life: 3000 });
                        }
                    }
                } else {
                    // Handle other errors
                    this.$toast.add({ severity: 'error', detail: 'An unexpected error occurred.', life: 3000 });
                }
            });
        },

        get_user_details(){
            
            axios.get(this.api_url+'customer/get-user-details',
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('mecompare_auth_token') 
            }
            }).then(response => {
                console.log('response', response)
                this.customer.full_name = response.data.data.full_name;
                this.customer.email = response.data.data.email;
                this.customer.phone = response.data.data.phone;
                this.customer.nationality = response.data.data.nationality;
                this.customer.image_preview = response.data.data.profile_image;
            });

        },
        onInput : function(phone, obj) {
            if(obj.valid) {
                this.is_phone_number_valid = true;
            } else {
                this.is_phone_number_valid = false;
            }
        },
        get_all_data : function() {
            axios.get(this.api_url+'motor/get_all_data').then((response) => {
                this.nationalities = response.data.data.nationalities;
            });
        },
        validate_email : function (email) {
            // eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        handleFileUpload(event) {
            const file = event.target.files[0];

            if (file) {
                // Validate file type (only PNG, JPG, and JPEG)
                const allowedTypes = ['image/png', 'image/jpeg'];
                if (!allowedTypes.includes(file.type)) {
                    this.$toast.add({ severity: 'error', detail: 'Only PNG, JPG, and JPEG files are allowed!', life: 3000 });
                    return;
                }

                // Validate file size (max 5MB)
                const maxSize = 2 * 1024 * 1024; // 5MB in bytes
                if (file.size > maxSize) {
                    this.$toast.add({ severity: 'error', detail: 'File size should not exceed 2MB!', life: 3000 });
                    return;
                }

                // Set file details
                this.customer.file = file;
                this.customer.file_name = file.name;
                this.customer.file_size = this.formatFileSize(file.size);

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.customer.image_preview = e.target.result; // Store the data URL
                };
                reader.readAsDataURL(file);
            }
        },
        formatFileSize(size) {
        if (size < 1024) {
            return size + ' Bytes';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        }
        },

    }
}
</script>

<style>

</style>