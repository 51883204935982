<template>
    <div class="signin-wrapper signin-wrapper-meCompare">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-leftArea">
                <div class="login-img">
                    <img class="" src="/login-bg.png">
                </div>
            </div>

            <div class="col-12 md:col-6 lg:col-6 signIn-form-rightArea justify-content-center">
                <!-- <div class="logo-area">
                    <router-link to="/">
                        <img class="site-logo" src="/logo.png">
                    </router-link>
                </div> -->

                <div class="welcome-text-sign">
                    <h2>Forgot Password</h2>
                    <p>No Worries, we'll send you reset instructions</p>
                </div>

                <!-- Sign In Form -->
                <div class="sign-form-area" id="signIn-from">
                    
                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <vue-tel-input 
                            mode="international"
                            validCharactersOnly
                            v-model="customer.phone"
                            default-country="ae"
                            :inputOptions="{placeholder : '+971555555555'}"
                            :dropdown-options="{onlyCountries: ['ae'], showFlags: true, disabled: true, showDialCodeInList: true, }"
                            @on-input="onInput"
                            ></vue-tel-input>
                        </span>
                    </div>

                    <div class="form-fields-main text-center mb-0">
                       <button-prime class="login-btn mb-2" :loading="loading" label="Continue" @click="send_otp_if_password_forgot" rounded />
                        <p class="botton-text-label">
                            Back to 
                            <router-link to="/sign-in">
                                <a>Sign In</a>
                            </router-link>
                        </p>
                    </div>
                </div>
                <!-- / Sign In Form -->
            </div>
        </div>
        <toast-prime />
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            loading : false,
            is_phone_number_valid : false,
            customer : {
                phone : null,
            }
        }
    },
    mounted() {
        
    },
    methods : {
        send_otp_if_password_forgot : function() {
            if (this.customer.phone == null || this.customer.phone == '') {
                this.$toast.add({ severity: 'error', detail: 'Phone number is required!', life: 3000 });
                return false;
            }
            if (!this.is_phone_number_valid) {
                this.$toast.add({ severity: 'error', detail: 'Invalid Phone number!', life: 3000 });
                return false; 
            }
            this.loading = true;
            axios.get(this.api_url+'send-customer-otp-for-password-forgot', {
            params : {
                phone : this.customer.phone.replace(/\s+/g, '')
            }
            }).then((response) => {
                if (response.data.data.status == true) {
                    this.loading = false;
                    this.$toast.add({ severity: 'success', detail: 'Otp sent successfully!', life: 3000 });
                    setTimeout(function () {
                        this.$router.push({
                            path : '/verify/'+response.data.data.id+'/verify-mobile',
                        });
                    }.bind(this), 2000);
                } else {
                    this.loading = false;
                    this.$toast.add({ severity: 'error', detail: response.data.data.message, life: 3000 });
                    
                    
                }
            });
        },
        onInput : function(phone, obj) {
            if(obj.valid) {
                this.is_phone_number_valid = true;
            } else {
                this.is_phone_number_valid = false;
            }
        },
        valid : function(obj) {
            if (obj.valid != undefined) {
                if(obj.valid) {
                    this.is_phone_number_valid = true;
                } else {
                    this.is_phone_number_valid = false;
                }
            } else {
                this.is_phone_number_valid = false;
            }
        },
    }
}
</script>